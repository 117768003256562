import { ComponentProps } from 'react'
import ReactSlider from 'react-slider'
import { twMerge } from 'tailwind-merge'

type Props<T extends number | readonly number[]> = Omit<
    ComponentProps<typeof ReactSlider<T>>,
    'thumbClassName' | 'trackClassName' | 'renderThumb' | 'renderTrack'
>

export default function SQSlider<T extends number | readonly number[]>({
    className,
    ...props
}: Props<T>) {
    return (
        <ReactSlider
            className={twMerge('h-6 w-full', className)}
            thumbClassName="top-1/2 -translate-y-1/2 size-5 hover:cursor-grab active:cursor-grabbing rounded-full border-2 border-black outline-none bg-white"
            trackClassName="top-1/2 -translate-y-1/2 h-1 rounded-lg"
            renderThumb={({ key, ...thumbProps }) => <div key={key} {...thumbProps}></div>}
            renderTrack={({ key, ...trackProps }, state) => (
                <div
                    key={key}
                    {...trackProps}
                    className={twMerge(
                        trackProps.className,
                        state.index > 0 &&
                            typeof state.value !== 'number' &&
                            state.index < state.value.length
                            ? 'bg-black'
                            : 'bg-sq-gray-200'
                    )}
                />
            )}
            {...props}
        />
    )
}
