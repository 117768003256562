export type RangeType = {
    min: number
    max: number
}

export type RegionType = {
    bairro: string
    count: number
    bairro_slug: string
    enabled?: boolean
    center_lat?: number
    center_lng?: number
}

export type LogradouroType = {
    logradouro: string
    center_lat?: number
    center_lng?: number
    count?: number
}

export type BairroLogradouroType = {
    bairro: string
    bairro_slug: string
    logradouro: string
    logradouro_slug: string
    center_lat?: number
    center_lng?: number
    count?: number
}

export type SearchValue =
    | {
          type: 'query'
          value: string
      }
    | {
          type: 'region'
          value: RegionType
      }
    | {
          type: 'logradouro'
          value: BairroLogradouroType
      }

export type ClosedRange = {
    type: 'closed'
    min: number
    max: number
}

export type OpenRange = {
    type: 'open'
    min: number
}

export type Range = OpenRange | ClosedRange

export type SupplySearchParams = {
    query: SearchValue
    size: Range | undefined
    price: Range | undefined
}

export const openRange = (min: number): OpenRange => ({ type: 'open', min })

export const closedRange = (min: number, max: number): ClosedRange => {
    if (min < max) {
        return { type: 'closed', min, max }
    }

    return { type: 'closed', min: max, max: min }
}
