import { FocusEventHandler, useCallback, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
    value: number
    maxValue?: number
    unitSymbol?: string
    locale?: string
    onChange: (value: number) => void
} & Omit<React.ComponentProps<'input'>, 'value' | 'onChange' | 'defaultValue'>

const parseValue = (value: string) => parseInt(value.replaceAll(/\D/g, ''))

export default function SizeInput({
    value,
    maxValue,
    onChange,
    unitSymbol = 'm²',
    locale = 'pt-BR',
    className,
    onBlur,
    onKeyUp,
    ...props
}: Props) {
    const formatValue = useCallback(
        (value: number, maxValue: number | undefined) => {
            if (maxValue && value >= maxValue) {
                return (
                    maxValue.toLocaleString(locale, {
                        maximumFractionDigits: 0,
                    }) + '+'
                )
            }
            return value.toLocaleString(locale, {
                maximumFractionDigits: 0,
            })
        },
        [locale]
    )

    const [localValue, setLocalValue] = useState<string>(formatValue(value, maxValue))

    const onChangeHandler = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => setLocalValue(e.target.value),
        []
    )

    const updateValue = useCallback(
        (value: number) => {
            if (isNaN(value)) return
            onChange(value)
            setLocalValue(formatValue(value, maxValue))
        },
        [onChange, formatValue]
    )

    const onBlurHandler = useCallback<FocusEventHandler<HTMLInputElement>>(
        (e) => {
            updateValue(parseValue(e.target.value))
            onBlur?.(e)
        },
        [value]
    )

    const onKeyUpHandler = useCallback<React.KeyboardEventHandler<HTMLInputElement>>(
        (e) => {
            if (e.key === 'Enter') {
                updateValue(parseValue(e.currentTarget.value))
            }
            onKeyUp?.(e)
        },
        [onBlurHandler]
    )

    useEffect(() => {
        setLocalValue(formatValue(value, maxValue))
    }, [value])

    return (
        <div className={twMerge('relative mt-2', className)}>
            <div className="pointer-events-none absolute right-0 top-0 mr-4 flex h-full items-center">
                <span className="text-sm">{unitSymbol}</span>
            </div>
            <input
                type="text"
                className="w-full rounded border border-sq-gray-200 bg-white p-4 pr-10 text-sm outline-none"
                value={localValue}
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
                onKeyUp={onKeyUpHandler}
                {...props}
            />
        </div>
    )
}
