import { ButtonTrackClickType, EventType, GetButtonEventProperties, track } from '@/tracks'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Supply } from 'src/@core/supply/supply.model'
import { toAddressLine1Supply } from 'src/@core/supply/supply.utils'
import supplyGateway, {
    NearbyQuery,
    SupplyQuery,
    SupplyResponse,
} from 'src/@core/supply/supply.gateway'
import { AuthContext } from 'src/context/auth'
import { buildListingSearchParams } from 'src/@core/supply/supply.tracking'

export const useFavoriteSupply = (supply: Supply | undefined) => {
    const { toggleFavorite, favorites, auth } = useContext(AuthContext)

    const [isFavorite, setIsFavorite] = useState<boolean>(
        supply ? (favorites?.[supply.id] ?? false) : false
    )

    useEffect(() => {
        setIsFavorite(
            supply !== undefined &&
                favorites !== undefined &&
                favorites[supply.id] !== undefined &&
                favorites[supply.id]
        )
    }, [favorites, supply, supply?.id])

    const title = supply ? toAddressLine1Supply(supply) : ''

    const trackFavoriteChange = useCallback(
        (isFavorite: boolean) =>
            track(EventType.ButtonClick, {
                ...GetButtonEventProperties(
                    `Supply - ${title} `,
                    isFavorite
                        ? ButtonTrackClickType.ListingAddFavorite
                        : ButtonTrackClickType.ListingRemoveFavorite
                ),
                supply: supply,
            }),
        [title]
    )

    const onToggleFavorite = useCallback(() => {
        if (!supply) return

        toggleFavorite?.(supply.id, !isFavorite)
        if (auth.user) {
            trackFavoriteChange(!isFavorite)
            setIsFavorite((f) => !f)
        }
    }, [auth.user, isFavorite, supply, toggleFavorite, trackFavoriteChange])

    return {
        isFavorite,
        toggleFavorite: onToggleFavorite,
    }
}

export const useSupplyBySlug = (slug: string | undefined) => {
    const [supply, setSupply] = useState<Supply | null>(null)
    const [isError, setIsError] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        if (!slug) {
            return
        }

        supplyGateway
            .get(slug)
            .then(setSupply)
            .catch(() => setIsError(true))
            .finally(() => setIsLoading(false))
    }, [slug])

    return { supply, isError, isLoading }
}

type SupplySearchProps = {
    results: Supply[]
    total: number
    pending: boolean
    error: Error | null
    doSearch: (query: SupplyQuery) => void
}

export function useSupplySearch(initialData: SupplyResponse | undefined): SupplySearchProps {
    const [results, setResults] = useState<Supply[]>(initialData ? initialData.data : [])
    const [total, setTotal] = useState<number>(initialData ? initialData.total : 0)
    const [pending, setPending] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null)

    const doSearch = useCallback(async (query: SupplyQuery) => {
        setPending(true)
        try {
            track(...buildListingSearchParams(query))
            const resp = await supplyGateway.search(query)
            setResults(resp.data)
            setTotal(resp.total)
        } catch (e: any) {
            setError(e.message)
        }
        setPending(false)
    }, [])

    return {
        results,
        total,
        pending,
        error,
        doSearch,
    }
}

type NearbySearchProps = {
    resultsNear: Supply[]
    totalNear: number
    pending: boolean
    error: Error | null
    doNearby: (query: NearbyQuery) => void
    cleanNearby: () => void
}

export function useNearbySearch(): NearbySearchProps {
    const [resultsNear, setResultsNear] = useState<Supply[]>([])
    const [totalNear, setTotalNear] = useState<number>(0)
    const [pending, setPending] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null)

    const doNearby = async (query: NearbyQuery) => {
        setPending(true)
        try {
            const resp = await supplyGateway.nearby(query)
            setResultsNear(resp.data)

            // Using page length, instead api total because we dont paginate nearby results.
            setTotalNear(resp.data.length)
        } catch (e: any) {
            setError(e.message)
        }
        setPending(false)
    }

    const cleanNearby = () => {
        setResultsNear([])
        setTotalNear(0)
    }

    return {
        resultsNear,
        totalNear,
        pending,
        error,
        doNearby,
        cleanNearby,
    }
}
