import { useCallback } from 'react'
import SQSlider from '@/_shared/SQSlider/SQSlider'
import SizeInput from './SizeInput'
import { Range } from 'src/@core/search/search.model'
import { rangeToSliderValue } from 'src/@core/search/search.utils'

const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max)

type Props = {
    value: Range
    min?: number
    max?: number
    minDistance?: number
    step?: number | undefined
    unitSymbol?: string
    locale?: string
    onChangeMin: (value: number) => void
    onChangeMax: (value: number) => void
}

export default function SizeFilter({
    value,
    min = 0,
    max = 700,
    minDistance = 100,
    step = 50,
    unitSymbol = 'm²',
    locale = 'pt-BR',
    onChangeMin,
    onChangeMax,
}: Props) {
    const onChangeSlider = useCallback(
        (values: readonly number[]) => {
            onChangeMin(values[0])
            onChangeMax(values[1])
        },
        [onChangeMin, onChangeMax]
    )

    return (
        <>
            <div className="my-4 flex flex-row gap-2 text-left">
                <div>
                    <label htmlFor="size-min">Mínimo</label>

                    <SizeInput
                        id="size-min"
                        value={value.min}
                        unitSymbol={unitSymbol}
                        locale={locale}
                        onChange={(newMin) => onChangeMin(clamp(newMin, min, max))}
                    />
                </div>
                <div>
                    <label htmlFor="size-max">Máximo</label>
                    <SizeInput
                        id="size-max"
                        value={value.type === 'open' ? max : value.max}
                        maxValue={max}
                        unitSymbol={unitSymbol}
                        locale={locale}
                        onChange={(newMax) => onChangeMax(clamp(newMax, min, max))}
                    />
                </div>
            </div>
            <SQSlider
                value={rangeToSliderValue(value, max)}
                min={min}
                max={max}
                step={step}
                minDistance={minDistance}
                onChange={onChangeSlider}
            />
        </>
    )
}
